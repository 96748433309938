import React, { useContext, useEffect, useRef, useState } from "react";
import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import PageBanner from "../components/common/PageBanner";
import Clients from "../components/common/Clients";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { StaticImage } from "gatsby-plugin-image";
import { Fragment } from "react";
import { Link } from "gatsby";
import TextBox from "../components/inputs/Textbox";

const News = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  let container1 = useRef(null);

  const [txtValues, setTxtValues] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTxtValues({ ...txtValues, [name]: value });
  };

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      const cn1 = container1.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
        cn1.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Latest News | VIRSAT'
        description='Find out the latest news of VIRSAT - Virtual Reality Safety Training'
      />
      {!state.showPageLoading && (
      <Fragment>
        <PageBanner title='Latest News'/>
          <section
            className='main-container articles hide-container'
            ref={container}
          >
            <div className='row top-pad bottom-pad fd-col'>
              
              <article className='column article-lists'>
                <article className='article-thumb' data-aos='fade-in'>
                  <Link to="/news/virsat-brings-new-virtual-reality-training-adipec-2022/">
                    <StaticImage
                      src='../assets/img/pages/newsletter/1/VIRSAT Brings New Virtual Reality Training in ADIPEC 2022.jpg'
                      alt='VIRSAT Brings New Virtual Reality Training in ADIPEC 2022'
                      placeholder='blurred'
                      objectFit
                      className='image'
                    />
                    <div className='details'>
                      <h3 className='main-subtitle center'>VIRSAT Brings New Virtual Reality Training in ADIPEC 2022</h3>
                      <p>Virtual Reality Safety Training (VIRSAT) is pleased to announce its participation in ADIPEC 2022 to launch a set of its new Virtual Reality innovations and products.</p>
                    </div>
                  </Link>
                </article>
              </article>
            </div>
          </section>
          <section className='main-container common-newsletter hide-container' ref={container1}>
            <div className="row fj-center fa-center bottom-mar" data-aos='fade-in'>
              <div className='column image'>
                <StaticImage
                  src='../assets/img/pages/articles/newsletter-subscription-image.png'
                  alt='Subscript to Newsletter'
                  placeholder='blurred'
                  objectFit
                  className='gatsby-img'
                />
              </div>
              <div className="column details">
                <h3 className="main-title small light no-mar">Subscribe to Newsletter</h3>
                <p className='no-mar light remove-br-mobile'>
                  Discover the latest innovation, new products released, <br/>offers and promotions, and upcoming events.
                </p>
                <div className='text-link flex fa-center'>
                  <TextBox
                    type='email'
                    id='email'
                    name='email'
                    placeholder='Eq. john@yourcompany.com'
                    txtBoxClass='regular-text'
                    onChange={handleInputChange}
                    value={txtValues.email}
                    required
                    divStyle={{width:'50%'}}
                    divClass='no-margin'
                  />
                  <Link className='button-link' to='/lp/newsletter' state={{'email': txtValues.email }}>Subscribe</Link>
                </div>
              </div>
            </div>
          </section>
          <Clients />
        </Fragment>
      )}
    </Layout>
  );
};

export default News;