import React, { useState } from "react";

const Textbox = ({
  type,
  //isEmail = true,
  name,
  id,
  placeholder,
  divClass,
  divStyle,
  txtBoxClass,
  txtBox_style,
  onChange = () => {},
  theRef,
  value = "",
  error = false,
  required = false,
  hideLabelOnStart = false,
  disabled = false,
  //pattern = "",
  autocomplete = "on",
}) => {
  const [activeBorder, setActiveBorder] = useState(false);
  const [hideLabelStat, setHideLabelStat] = useState(hideLabelOnStart);

  const handleFocus = () => {
    setActiveBorder(true);
    setHideLabelStat(false);
  };

  const handleBlur = () => {
    setActiveBorder(false);
  };

  return (
    <div
      className={`field-container light-border ${
        error ? "error" : ""
      } ${divClass} ${activeBorder && "active"}`}
      style={divStyle}
    >
      <label
        className={`${value && "always-active"} ${
          hideLabelStat && "invisible"
        }`}
        htmlFor={id}
      >
        {placeholder}
      </label>
      <input
        className={txtBoxClass}
        style={{ txtBox_style }}
        type={type}
        name={name}
        id={id}
        placeholder={hideLabelStat ? placeholder : ""}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        ref={theRef}
        value={value}
        required={required}
        disabled={disabled}
        autoComplete={autocomplete}
      />
    </div>
  );
};

export default Textbox;
